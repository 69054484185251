import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { Box, VStack, Image, Hide, useToast } from '@chakra-ui/react'

import { useMutationPostAuthLocalLogin } from '@/api/auth/mutations'
import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import S from '@/common/Templates/TemplateLogin/styles'
import { InputWithMask, Input } from '@/components/Forms'
import { getErrorDetails } from '@/utils/error'

export interface IFormValues {
  cpf: string
  password: string
}

const LoginDev: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { mutate: postAuthLocalLogin, isLoading: isLoadingAuthLocalLogin } =
    useMutationPostAuthLocalLogin()

  const handleSubmit = (values: IFormValues) => {
    const { cpf, password } = values
    const cpfWithoutMask = cpf.replace(/\D/g, '')

    postAuthLocalLogin(
      {
        dsCpf: cpfWithoutMask,
        dsPassword: password,
      },
      {
        onSuccess: data => {
          localStorage.setItem('access-token', data.access)
          localStorage.setItem('refresh-token', data.refresh)

          if (data.firstAccess) {
            navigate('/login/primeiro-acesso')

            return
          }

          navigate('/')
        },
        onError: error => {
          const statusCode = error.response?.status

          if (statusCode === 401) {
            navigate('/login/acesso-negado')

            return
          }

          toast({
            status: 'error',
            title: 'Ops! Ocorreu um erro',
            description: getErrorDetails(error),
            isClosable: true,
          })
        },
      }
    )
  }

  return (
    <S.MainBox maxWidth="564px" backgroundColor="white">
      <Hide below="sm">
        <Box display="flex" justifyContent="center">
          <Image height="75px" src={ColorfulLogoSARE} alt="Logo Sare" />
        </Box>
      </Hide>
      <S.Title color="brand.neutral.dark_2">
        Seja bem-vindo(a) ao
        <br />
        Sistema de Análise de Recursos Educacionais
      </S.Title>
      <Formik
        initialValues={{
          cpf: '',
          password: '',
        }}
        validationSchema={yup.object().shape({
          cpf: yup
            .string()
            .required('Este campo é obrigatório')
            .test('test-cpf', 'O CPF está incompleto', value => {
              return !value.includes('_')
            }),
          password: yup.string().required('Este campo é obrigatório'),
        })}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <VStack width="full" gap="4">
            <InputWithMask
              name="cpf"
              label="CPF"
              mask="999.999.999-99"
              placeholder="Digite seu CPF"
              inputProps={{
                fontSize: 'md',
              }}
            />
            <Input
              name="password"
              label="Senha"
              type="password"
              placeholder="Digite sua senha"
              inputProps={{
                fontSize: 'md',
              }}
            />
          </VStack>
          <Box width="full" marginTop="8">
            <S.MainButton
              type="submit"
              variant="solid"
              border="none"
              color="white"
              backgroundColor="#C63637"
              _hover={{ backgroundColor: '#C63637EB', color: 'white' }}
              width="full"
              isLoading={isLoadingAuthLocalLogin}
            >
              Entrar como desenvolvedor
            </S.MainButton>
          </Box>
        </Form>
      </Formik>
    </S.MainBox>
  )
}

export default LoginDev
